const numberTypeError = 'Value must be a number';
const inputsForFields = {
  COURSE_NAME: {
    name: 'COURSE_NAME',
    type: 'string',
    inputType: 'input',
    rules: { type: 'string' },
    errors: [],
    ordinal: 1,
  },
  COURSE_NUMBER: {
    name: 'COURSE_NUMBER',
    type: 'string',
    inputType: 'input',
    rules: { type: 'string' },
    errors: [],
    ordinal: 2,
  },
  COURSE_DESCRIPTION: {
    name: 'COURSE_DESCRIPTION',
    type: 'string',
    inputType: 'textarea',
    rules: { type: 'string' },
    errors: [],
    ordinal: 3,
  },
  COURSE_CREDIT: {
    name: 'COURSE_CREDIT',
    type: 'number',
    inputType: 'input',
    rules: { type: 'number', typeError: numberTypeError },
    errors: [],
    ordinal: 4,
  },
  COURSE_TUITION: {
    name: 'COURSE_TUITION',
    type: 'currency',
    inputType: 'input',
    rules: { type: 'string' },
    errors: [],
    ordinal: 5,
  },
  COURSE_BOOKS: {
    name: 'COURSE_BOOKS',
    type: 'currency',
    inputType: 'input',
    rules: { type: 'number', typeError: numberTypeError },
    errors: [],
    ordinal: 6,
  },
  COURSE_LABS: {
    name: 'COURSE_LABS',
    type: 'currency',
    inputType: 'input',
    rules: { type: 'number', typeError: numberTypeError },
    errors: [],
    ordinal: 7,
  },
  COURSE_FEES: {
    name: 'COURSE_FEES',
    type: 'currency',
    inputType: 'input',
    rules: { type: 'number', typeError: numberTypeError },
    errors: [],
    ordinal: 8,
  },
  COURSE_SOFTWARE: {
    name: 'COURSE_SOFTWARE',
    type: 'currency',
    inputType: 'input',
    rules: { type: 'number', typeError: numberTypeError },
    errors: [],
    ordinal: 9,
  },
  COURSE_GRADE: {
    name: 'COURSE_GRADE',
    type: 'string',
    inputType: 'select',

    rules: { type: 'string' },
    options: [{ value: 'A' }, { value: 'B' }, { value: 'C' }, { value: 'D' }, { value: 'F' }],
    selectionMode: 'single',

    errors: [],
    ordinal: 10,
  },
};

export { inputsForFields };

export default null; // silence a false warning
