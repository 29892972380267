import { attr, belongsTo, hasMany } from '@ember-data/model';
import { action } from '@ember/object';
import { cached } from '@glimmer/tracking';
import { service } from '@ember/service';
import {
  getSingleValueForTasField,
  setSingleValueForTasField,
} from '../utils/tuition-assistance/fields.ts';
import CommentableModel from './commentable.ts';
import type UserModel from './user.ts';
import type Intl from 'ember-intl/services/intl';
import type Store from '@ember-data/store';
import type TASProgramInstanceModel from './tas-program-instance.ts';
import type TasProgramLimitModel from './tas-program-limit.ts';
import type TASCourseModel from './tas-course.ts';
import type TASAssetModel from './tas-asset.ts';
import type TagModel from './tag.ts';
import type AttachmentModel from './attachment.ts';
import type TasAssistanceModel from './tas-assistance.ts';
import calculatePrePercentageValue from '../utils/tuition-assistance/calculate-pre-percentage-value.ts';
import type {
  CustomFieldSignature,
  TransitionLogSignature,
  ScholarshipsReceivedField,
  StringSingleValueField,
  NumberSingleValueField,
  ApplicationApprovalSummary,
  ApplicationApprovalSummaryApprover,
  TasApplicationState,
  TasApplicationPaymentState,
  PaymentStateLogSignature,
  BooleanSingleValueField,
  ClaimsFinancialsSingleValueField,
  TasClaimsFinancials,
  TasAppHistory,
} from '../types/tuition-assistance';
import { createDefaultClaimsFinancialsWithBenefitPercent } from '../utils/tuition-assistance/claims-financials-calculator.ts';
import ClaimsFinancialsCalculator from '../utils/tuition-assistance/claims-financials-calculator.ts';

export type TASApplicationModelFieldsSignature = {
  APPLICATION_NAME: StringSingleValueField;
  CLAIMS_FINANCIALS: ClaimsFinancialsSingleValueField;
  COURSES_BEGIN_DATE: StringSingleValueField;
  COURSES_END_DATE: StringSingleValueField;
  INVOICE_DATE: StringSingleValueField;
  SCHOLARSHIPS_RECEIVED: ScholarshipsReceivedField;
  PRE_APPROVED_TOTAL: NumberSingleValueField;
  FINAL_APPROVED_TOTAL: NumberSingleValueField;
  WAIVE_EVIDENCE_ATTACHMENT_REQUIREMENT: BooleanSingleValueField;
  WAIVE_EVIDENCE_APPROVAL_REQUIREMENT: BooleanSingleValueField;
  EVIDENCE_APPROVAL_WAIVED_BY: NumberSingleValueField; // User ID
  SEMESTER_CODE: StringSingleValueField;
  EXPENSE_TYPE: StringSingleValueField;
};

export type ApprovedTotalLogSignature = {
  field_name: 'PRE_APPROVED_TOTAL' | 'FINAL_APPROVED_TOTAL';
  from: number;
  to: number;
  reason: string;
  timestamp: string;
  by_user_id: UserModel['id'];
  via_method: string;
};

export default class TasApplicationModel extends CommentableModel {
  @service declare intl: Intl;
  @service declare store: typeof Store;

  @attr declare state: TasApplicationState;
  @attr declare fields: TASApplicationModelFieldsSignature;
  @attr declare customFields: CustomFieldSignature[];
  @attr declare transitionLog: TransitionLogSignature[];
  @attr declare paymentStateLog: PaymentStateLogSignature[];
  @attr declare approvedTotalLog: ApprovedTotalLogSignature[];
  @attr declare createdAt: string;
  @attr declare updatedAt: string;
  @attr declare coursesApproval: ApplicationApprovalSummary;
  @attr declare evidenceApproval: ApplicationApprovalSummary;
  @attr declare migrationApplicationId: string;
  @attr declare isTrial: boolean;
  @attr declare paymentState: TasApplicationPaymentState;
  @attr declare history: TasAppHistory[];

  /*************************
   **  Relationships      **
   *************************/

  @belongsTo('tas-program-instance', {
    async: false,
    inverse: 'tasApplications',
  })
  declare tasProgramInstance: TASProgramInstanceModel;
  @belongsTo('tas-program-limit', { async: false, inverse: 'tasApplications' })
  declare tasProgramLimit: TasProgramLimitModel;
  @hasMany('tas-assistance', { async: false, inverse: 'tasApplication' })
  declare tasAssistances: TasAssistanceModel[];
  @hasMany('tas-course', { async: false, inverse: 'tasApplication' })
  declare tasCourses: TASCourseModel[];
  @hasMany('tas-asset', { async: false, inverse: 'tasApplications' })
  declare tasAssets: TASAssetModel[];
  @hasMany('attachment', { async: false, inverse: null })
  declare attachments: AttachmentModel[];
  @hasMany('tag', {
    async: false,
    inverse: 'taggable',
    as: 'taggable',
  })
  declare tags: TagModel[];

  /**************************
   **  Computed Properties **
   **************************/

  get activeCourses() {
    return this.tasCourses.filter((course) => course.isActive);
  }

  get applicant(): string {
    return getSingleValueForTasField('NAME', this.tasProgramInstance.fields);
  }

  get applicationName(): string {
    return getSingleValueForTasField('APPLICATION_NAME', this.fields);
  }

  get coursesBeginDate(): string {
    return getSingleValueForTasField('COURSES_BEGIN_DATE', this.fields);
  }

  get expenseType(): string {
    return getSingleValueForTasField('EXPENSE_TYPE', this.fields);
  }

  get isDraft() {
    return this.state === 'TAS.ApplicationState.DEFINE_COURSES';
  }

  get isApproved() {
    return this.approvedStates.has(this.state);
  }

  get isPaid() {
    return this.paidStates.has(this.paymentState);
  }

  get canCancelApplication() {
    const allowedStates = this.canDeleteAppStates;
    if (!this.isPrepaidProgram) allowedStates.add('TAS.ApplicationState.ATTEND');

    return allowedStates.has(this.state);
  }

  get canSubmitEvidence() {
    const courseApprovalRequired =
      this.tasProgramInstance?.tasProgramTemplate?.courseApprovalRequired;

    const validStates = ['TAS.ApplicationState.ATTEND', 'TAS.ApplicationState.COURSES_APPROVED'];

    if (!courseApprovalRequired) {
      validStates.push('TAS.ApplicationState.PENDING_COURSES_APPROVAL');
    }

    return validStates.includes(this.state);
  }

  get canSubmitForPayment() {
    const courseApprovalRequired =
      this.tasProgramInstance?.tasProgramTemplate?.courseApprovalRequired;

    const validStates = ['TAS.ApplicationState.ATTEND', 'TAS.ApplicationState.COURSES_APPROVED'];

    if (!courseApprovalRequired) {
      validStates.push('TAS.ApplicationState.PENDING_COURSES_APPROVAL');
    }

    return validStates.includes(this.state);
  }

  get approverCanEditApplication() {
    return this.approverCanEditStates.has(this.state);
  }

  get fieldForEditableApprovedTotal() {
    if (this.state === 'TAS.ApplicationState.PENDING_COURSES_APPROVAL') {
      return 'PRE_APPROVED_TOTAL';
    }
    if (this.state === 'TAS.ApplicationState.PENDING_EVIDENCE_APPROVAL') {
      return 'FINAL_APPROVED_TOTAL';
    }
    return 'FINAL_APPROVED_TOTAL';
  }

  get isCourseGradeRequired() {
    return this.tasProgramInstance?.tasProgramTemplate?.courseGradeRequired;
  }

  get isMissingCourseGrades() {
    return !this.activeCourses.every((course) => course.hasGrades);
  }

  get isEvidenceRequired() {
    if (getSingleValueForTasField('WAIVE_EVIDENCE_ATTACHMENT_REQUIREMENT', this.fields) === true) {
      return false;
    }

    return this.tasProgramInstance?.tasProgramTemplate?.requireAttachmentsForCourseCompletion;
  }

  get isPrepaidProgram() {
    return this.tasProgramInstance?.tasProgramTemplate?.programPaymentType === 'ON_ATTEND';
  }

  @cached
  get evidenceAssets() {
    // evidenceAssets for TYPE 2 programs are stored at the application level
    return Object.freeze(
      this.tasAssets.filter((asset) => asset.classification === 'COMPLETION_DOCUMENTS')
    );
  }

  get typeClassification() {
    return this.tasProgramInstance?.tasProgramTemplate?.typeClassification || '';
  }

  get hasRequiredEvidence() {
    if (!this.isEvidenceRequired) {
      return true;
    }
    const isTypeTwo = this.typeClassification === 'TAS.ProgramType.2';
    const hasApplicationEvidence = !!this.evidenceAssets.length;
    const allCoursesHaveEvidence = this.activeCourses.every(
      (course) => !!course.evidenceAssets.length
    );

    // If type 2, check for evidence at the application level or for all courses
    // doing this to make it backwards compatible with legacy programs
    return isTypeTwo ? hasApplicationEvidence : allCoursesHaveEvidence;
  }

  get isMissingRequiredEvidence() {
    return !this.hasRequiredEvidence;
  }

  get displayNameOrSemesterCode() {
    if (this.semesterCodeIsVisible) {
      return this.semesterCode;
    }
    const storedValue = getSingleValueForTasField('APPLICATION_NAME', this.fields);
    return (
      storedValue ??
      this.intl.formatDate(this.createdAt, {
        month: 'long',
        year: 'numeric',
      })
    );
  }

  get latestStateChangeLabel() {
    const latestTransition =
      this.transitionLog?.length > 0 ? this.transitionLog[this.transitionLog.length - 1] : null;
    return latestTransition?.to || '';
  }

  get latestStateChangeDate() {
    const latestTransition =
      this.transitionLog?.length > 0 ? this.transitionLog[this.transitionLog.length - 1] : null;

    // logic to handle multiple approvers required so that the latest state change date matches the submitted for course approval date if it's still undergoing the approval process
    if (
      latestTransition?.from === 'TAS.ApplicationState.COURSES_APPROVED' &&
      latestTransition?.to === 'TAS.ApplicationState.PENDING_COURSES_APPROVAL'
    ) {
      return this.courseSubmittedDate;
    }

    return latestTransition?.timestamp || '';
  }

  get latestStateChangePerson() {
    const latestTransition =
      this.transitionLog?.length > 0 ? this.transitionLog[this.transitionLog.length - 1] : null;
    return latestTransition?.reason?.by?.name || '';
  }

  get courseSubmittedDate() {
    // Find the first occurrence of the event 'request_courses_approval' in the transition log which would be when employee submits
    const transition = this.transitionLog.find(
      (transition) => transition.event === 'request_courses_approval'
    );
    return transition?.timestamp || '';
  }

  get approvedDate() {
    const transition = this.transitionLog.find(
      (transition) => transition.event === 'approve_evidence' || transition.event === 'attend'
    );
    return transition?.timestamp || '';
  }

  get requestedTotalAllCourses() {
    const sum = this.activeCourses
      .map((course) => course.courseTotal)
      .reduce((a, b) => {
        return a + b;
      }, 0);
    // This is in pennies, we should never have a decimal.
    // Rounding this here in case migrated data has decimals.
    return Math.round(sum);
  }

  get requestedTotal() {
    if (this.requestedTotalAllCourses === 0) {
      return 0;
    }
    const totalLessScholarships = this.requestedTotalAllCourses - this.scholarshipsTotal;

    if (this.tasProgramInstance?.tasProgramTemplate?.calculateTotalByPercent) {
      return (
        totalLessScholarships *
        ((this.tasProgramInstance?.tasProgramTemplate?.reimbursementPercentage || 100) / 100)
      );
    }

    return totalLessScholarships;
  }

  get requestedTotalCalculationFormulaNote() {
    if (this.tasProgramInstance?.tasProgramTemplate?.calculateTotalByPercent) {
      const reimbursementPercentage =
        this.tasProgramInstance?.tasProgramTemplate?.reimbursementPercentage;
      const prePercentageValue = calculatePrePercentageValue(
        this.requestedTotal,
        reimbursementPercentage
      );
      const formattedOriginalTotal = this.intl.formatNumber(prePercentageValue / 100, {
        style: 'currency',
        currency: 'USD',
        maximumFractionDigits: 2,
      });
      const formattedTotal = this.intl.formatNumber(this.requestedTotal / 100, {
        style: 'currency',
        currency: 'USD',
        maximumFractionDigits: 2,
      });
      return this.intl.t(
        'tuition_assistance.application_details.reimbursement_calculation_by_percent_formula',
        {
          percent: reimbursementPercentage,
          originalTotalInDollars: formattedOriginalTotal,
          totalInDollars: formattedTotal,
        }
      );
    }

    if (this.tasProgramInstance?.tasProgramTemplate?.calculateTotalByCredit) {
      return this.explanationOfReimbursementByCredit;
    }

    return '';
  }

  get explanationOfReimbursementByCredit() {
    if (!this.tasProgramInstance?.tasProgramTemplate?.calculateTotalByCredit) {
      return '';
    }

    // Reimbursement by credit calculations are done before subtracting scholarship amount
    const requestedTotalBeforeScholarships = this.requestedTotalAllCourses;

    const rate = this.tasProgramInstance?.tasProgramTemplate?.ratePerCredit || 0;
    const formattedRate = this.intl.formatNumber(rate / 100, {
      style: 'currency',
      currency: 'USD',
      maximumFractionDigits: 2,
    });
    const formattedTotalBeforeScholarships = this.intl.formatNumber(
      requestedTotalBeforeScholarships / 100,
      {
        style: 'currency',
        currency: 'USD',
        maximumFractionDigits: 2,
      }
    );
    return this.intl.t(
      'tuition_assistance.application_details.reimbursement_calculation_by_credit_formula',
      {
        rateInDollars: formattedRate,
        noOfCredits: requestedTotalBeforeScholarships / rate,
        totalInDollars: formattedTotalBeforeScholarships,
      }
    );
  }

  get scholarships() {
    return this.fields?.['SCHOLARSHIPS_RECEIVED']?.values || [];
  }

  get scholarshipsTotal() {
    const sum = this.scholarships.reduce((sum, scholarship) => {
      return sum + (scholarship.scholarshipAmount || 0);
    }, 0);
    // This is in pennies, we should never have a decimal.
    // Rounding this here in case migrated data has decimals.
    return Math.round(sum);
  }

  get includesScholarships() {
    return this.scholarshipsTotal > 0;
  }

  get approvedTotal() {
    return (getSingleValueForTasField('FINAL_APPROVED_TOTAL', this.fields) ??
      getSingleValueForTasField('PRE_APPROVED_TOTAL', this.fields)) as number | undefined;
  }

  get paidTotal() {
    return this.tasAssistances.reduce(
      (total, assistance) =>
        assistance.state === 'TAS.AssistanceState.SETTLED' ? total + assistance.amount : total,
      0
    );
  }

  get paidDate() {
    let latestTimestamp: string | null = null;

    this.tasAssistances.forEach((assistance: TasAssistanceModel) => {
      const settledDate = assistance.settledDate;

      if (settledDate && (!latestTimestamp || settledDate > latestTimestamp)) {
        latestTimestamp = settledDate;
      }
    });

    return latestTimestamp;
  }

  get modelName() {
    return 'tas-application';
  }

  get currentExpectedApproverEmployeeIds() {
    const approvalConfigType =
      this.state === 'TAS.ApplicationState.PENDING_EVIDENCE_APPROVAL'
        ? 'evidenceApproval'
        : 'coursesApproval';
    const reviewers: ApplicationApprovalSummaryApprover[] =
      this[approvalConfigType]?.current_reviewers || [];
    return new Set(reviewers.map((reviewer) => reviewer['employee_id']));
  }

  get allPossibleApproverEmployeeIdsForAllWorkflows() {
    const evidenceReviewers = this.evidenceApproval.possible_reviewers || [];
    const courseReviewers = this.coursesApproval.possible_reviewers || [];
    return new Set(
      [...evidenceReviewers, ...courseReviewers].map((reviewer) => reviewer['employee_id'])
    );
  }

  get isRefundRequested() {
    return this.paymentState === 'TAS.ApplicationPaymentState.REFUND_REQUESTED';
  }

  get amountOwed() {
    let amount = 0;
    for (const assistance of this.tasAssistances) {
      if (assistance.state === 'TAS.AssistanceState.REPAYABLE') {
        amount += Math.abs(assistance.amount);
      }
    }
    return amount;
  }

  get isInCollections() {
    return (this.tags || []).some((tag) => tag.name === 'In Collections');
  }

  get shouldDisplayApprovedTotal() {
    // Only display approved total if the application is in state ATTEND and up
    return !this.preAttendStates.has(this.state);
  }

  get isDormantState() {
    return this.dormantStates.has(this.state);
  }

  get certificateApplicationHasSubmitted() {
    return (
      this.tasProgramInstance?.tasProgramTemplate?.isCertificateProgram &&
      this.hasSubmittedApplicationStates.has(this.state)
    );
  }

  get applicationHasSubmittedForEvidenceApproval() {
    return this.postSubmitForPaymentStates.has(this.state);
  }

  // If the application allows for post-approval edits to approved total.
  get isEditableApprovedTotal() {
    const isUneditable =
      this.uneditableApprovedTotalPaymentStates.has(this.paymentState) ||
      this.uneditableApprovedTotalApplicationStates.has(this.state);
    return !isUneditable;
  }

  // If the application allows for request repayment (clawback).
  get canRequestRepayment() {
    return this.clawbackablePaymentStates.has(this.paymentState);
  }

  get latestPaymentDate() {
    return this.paymentStateLog.at(-1)?.reason?.payment?.date;
  }

  get semesterCodeIsVisible() {
    return this.tasProgramInstance.tasProgramTemplate.semesterCodeIsVisible;
  }

  get semesterCode() {
    return getSingleValueForTasField('SEMESTER_CODE', this.fields);
  }

  get totalCredits() {
    return this.activeCourses.reduce((total, course) => total + course.courseCredit, 0);
  }

  get totalPassingCredits() {
    return this.activeCourses.reduce((total, course) => total + course.passingCredit, 0);
  }

  get fractionalPercentPassed(): number {
    if (this.totalCredits === 0) return 0;
    // If the application is in the PENDING_COURSES_APPROVAL state, the fractional percent
    // passed should be 1. This is because the course has to be completed before a grade
    // can be assigned and a true fractional percent passed can be calculated.
    if (this.state === 'TAS.ApplicationState.PENDING_COURSES_APPROVAL') {
      return 1;
    }
    const rawPercentage = this.totalPassingCredits / this.totalCredits;
    return parseFloat(rawPercentage.toFixed(2));
  }

  // Ensure we have a properly initialized TasClaimsFinancials object when reading data.
  // `fractionPercentPassed` is a dynamic value throughout the lifecycle of the application
  // so any new calculations must be made with the most current value.
  get claimsFinancialsCalculatorInput(): Readonly<TasClaimsFinancials> {
    const defaultCF = createDefaultClaimsFinancialsWithBenefitPercent(
      this.tasProgramInstance?.tasProgramTemplate?.fractionalBenefitPercentForClaimsFinancials,
      this.requestedTotal
    );
    const currentCF = this.fields['CLAIMS_FINANCIALS'].values[0] || {};
    return {
      ...defaultCF,
      ...currentCF,
      CF_FRACTIONAL_PERCENT_PASSED: this.fractionalPercentPassed,
    };
  }

  get paymentsHistory() {
    return (this.history || []).filter((item) => item.logType === 'assistance');
  }

  @action
  recalculateClaimsFinancialsAfterAdjacentUpdate(): TasClaimsFinancials {
    const calculator = new ClaimsFinancialsCalculator(this.claimsFinancialsCalculatorInput);
    // @ts-expect-error: Genuinely confused. This does exist on here and the fields are application fields type.
    setSingleValueForTasField('CLAIMS_FINANCIALS', this.fields, calculator.results);
    return calculator.results;
  }

  @action
  async toggleInCollections(): Promise<void | TagModel> {
    if (this.isInCollections) {
      const tag = this.tags.find((tag) => tag.name === 'In Collections');
      if (tag) {
        tag.destroyRecord();
      }
    } else {
      const tag = this.store.createRecord('tag', {
        name: 'In Collections',
        taggable: this,
        taggableType: 'TasApplication',
      });
      await tag.save();
      return tag;
    }
  }

  @action
  async loadHistory(): Promise<void | TasAppHistory> {
    const history = await this.store.adapterFor('tas-application').getHistory(this);
    this.history = history.data;
  }

  preAttendStates: Set<TasApplicationState> = new Set([
    'TAS.ApplicationState.DEFINE_COURSES',
    'TAS.ApplicationState.PENDING_COURSES_APPROVAL',
    'TAS.ApplicationState.COURSES_APPROVED',
    'TAS.ApplicationState.COURSES_NOT_APPROVED',
    'TAS.ApplicationState.COURSES_REJECTED',
  ]);

  dormantStates: Set<TasApplicationState> = new Set([
    'TAS.ApplicationState.COURSES_REJECTED',
    'TAS.ApplicationState.EVIDENCE_REJECTED',
    'TAS.ApplicationState.FULFILLED',
    'TAS.ApplicationState.ABANDONED',
  ]);

  canDeleteAppStates: Set<TasApplicationState> = new Set([
    'TAS.ApplicationState.PENDING_COURSES_APPROVAL',
    'TAS.ApplicationState.PENDING_EVIDENCE_APPROVAL',
    'TAS.ApplicationState.DEFINE_COURSES',
  ]);

  clawbackablePaymentStates = new Set([
    'TAS.ApplicationPaymentState.REFUND_REQUESTED',
    'TAS.ApplicationPaymentState.PAID_IN_FULL',
    'TAS.ApplicationPaymentState.PAID_IN_PART',
  ]);

  uneditableApprovedTotalPaymentStates = new Set([
    'TAS.ApplicationPaymentState.REFUND_REQUESTED',
    'TAS.ApplicationPaymentState.PAID_IN_FULL',
  ]);

  uneditableApprovedTotalApplicationStates: Set<TasApplicationState> = new Set([
    'TAS.ApplicationState.COURSES_REJECTED',
    'TAS.ApplicationState.EVIDENCE_REJECTED',
    'TAS.ApplicationState.ABANDONED',
  ]);

  postSubmitForPaymentStates: Set<TasApplicationState> = new Set([
    'TAS.ApplicationState.PENDING_EVIDENCE_APPROVAL',
    'TAS.ApplicationState.EVIDENCE_APPROVED',
    'TAS.ApplicationState.EVIDENCE_NOT_APPROVED',
    'TAS.ApplicationState.EVIDENCE_REJECTED',
    'TAS.ApplicationState.FULFILLED',
    'TAS.ApplicationState.ABANDONED',
  ]);

  hasSubmittedApplicationStates: Set<TasApplicationState> = new Set([
    ...this.dormantStates,
    ...this.uneditableApprovedTotalApplicationStates,
    ...this.postSubmitForPaymentStates,
    'TAS.ApplicationState.ATTEND',
    'TAS.ApplicationState.PENDING_COURSES_APPROVAL',
    'TAS.ApplicationState.COURSES_APPROVED',
    'TAS.ApplicationState.COURSES_NOT_APPROVED',
    'TAS.ApplicationState.COURSES_REJECTED',
  ]);

  approvedStates: Set<TasApplicationState> = new Set([
    'TAS.ApplicationState.COURSES_APPROVED',
    'TAS.ApplicationState.PENDING_EVIDENCE_APPROVAL',
    'TAS.ApplicationState.ATTEND',
    'TAS.ApplicationState.FULFILLED',
    'TAS.ApplicationState.EVIDENCE_APPROVED',
  ]);

  paidStates: Set<string> = new Set([
    'TAS.ApplicationPaymentState.PAID_IN_FULL',
    'TAS.ApplicationPaymentState.PAID_IN_PART',
  ]);

  approverCanEditStates: Set<TasApplicationState> = new Set([
    'TAS.ApplicationState.ATTEND',
    'TAS.ApplicationState.PENDING_COURSES_APPROVAL',
    'TAS.ApplicationState.COURSES_APPROVED',
    'TAS.ApplicationState.COURSES_NOT_APPROVED',
    'TAS.ApplicationState.COURSES_REJECTED',
    'TAS.ApplicationState.PENDING_EVIDENCE_APPROVAL',
    'TAS.ApplicationState.EVIDENCE_APPROVED',
    'TAS.ApplicationState.EVIDENCE_NOT_APPROVED',
    'TAS.ApplicationState.EVIDENCE_REJECTED',
  ]);

  restingStates: Set<TasApplicationState> = new Set([
    'TAS.ApplicationState.ABANDONED',
    'TAS.ApplicationState.FULFILLED',
    'TAS.ApplicationState.EVIDENCE_REJECTED',
    'TAS.ApplicationState.PENDING_EVIDENCE_APPROVAL',
    'TAS.ApplicationState.ATTEND',
    'TAS.ApplicationState.COURSES_REJECTED',
    'TAS.ApplicationState.PENDING_COURSES_APPROVAL',
    'TAS.ApplicationState.DEFINE_COURSES',
  ]);
}
declare module '@ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'tas-application': TasApplicationModel;
  }
}
